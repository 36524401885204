.navLink {
  color: var(--text-secondary);
  text-decoration: none;
  display: inline-flex;
  gap: 1em;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  width: fit-content;
}

.navLink:hover {
  color: var(--primary-hover);
}

.navLink:active {
  color: var(--primary);
}

.navLinkActive {
  color: var(--primary);
}

.navLink svg {
  flex-shrink: 0;
}


