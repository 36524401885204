.wrapper {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100vh;
  background-color: var(--bg-hover);
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.content {
  background: var(--white);
  padding: 48px;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0,0,0,.25);
  border: 1px solid var(--border-color);
  gap: 36px;
  width: 430px !important;
  height: max-content;
  position: absolute;
  top: 190px;
  left: calc(50% - 215px);
  z-index: 2;
}

.controllers {
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.fields {
  gap: 24px;
  margin-bottom: 1em;
}

.err {
  margin-top: -20px;
}

.logo {
  margin-right: auto;
  margin-bottom: 1.5em;
}

.logoIcon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100px;
  height: 80vh;
  z-index: 1;
  opacity: 0.5;
}
