.materialInput {
  --pure-material-safari-helper1: var(--primary);
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  width: 100%;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Input, Textarea */
.materialInput > input,
.materialInput > textarea {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px; /* Safari */
  border-color: rgba(92, 92, 119, 0.28);
  border-top-color: transparent;
  border-radius: var(--input-border-radius);
  padding: 15px 13px 15px;
  width: 100%;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: var(--primary);
  height: var(--input-height);
  background: #fff;
}

/* Span */
.materialInput > input + span,
.materialInput > textarea + span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-color: rgba(92, 92, 119, 0.28);
  width: 100%;
  max-height: 100%;
  font-size: 75%;
  color: var(--placeholder);
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Corners */
.materialInput > input + span::before,
.materialInput > input + span::after,
.materialInput > textarea + span::before,
.materialInput > textarea + span::after {
  content: '';
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: rgba(92, 92, 119, 0.28);
  min-width: 10px;
  height: 8px;
  pointer-events: none;
}

.materialInput > input + span::before,
.materialInput > textarea + span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.materialInput > input + span::after,
.materialInput > textarea + span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px;
}

/* Placeholder-shown */
.materialInput > input:not(:focus):placeholder-shown,
.materialInput > textarea:not(:focus):placeholder-shown {
  border-top-color: rgba(92, 92, 119, 0.28);
}

.materialInput > input:not(:focus):placeholder-shown + span,
.materialInput > textarea:not(:focus):placeholder-shown + span {
  font-size: inherit;
  line-height: 51px;
}

.materialInput > input:not(:focus):placeholder-shown + span::before,
.materialInput > textarea:not(:focus):placeholder-shown + span::before,
.materialInput > input:not(:focus):placeholder-shown + span::after,
.materialInput > textarea:not(:focus):placeholder-shown + span::after {
  border-top-color: transparent;
}

/* Focus */
.materialInput > input:focus,
.materialInput > textarea:focus {
  border-color: var(--primary);
  border-top-color: transparent;
  outline: none;
}

.materialInput > input:focus + span,
.materialInput > textarea:focus + span {
  color: var(--primary);
}

.materialInput > input:focus + span::before,
.materialInput > input:focus + span::after,
.materialInput > textarea:focus + span::before,
.materialInput > textarea:focus + span::after {
  border-top-color: var(--pure-material-safari-helper1) !important;
}

/* Invalid */
.materialInput > input:not(:focus):invalid,
.materialInput > textarea:not(:focus):invalid {
  border-color: var(--alert);
  border-top-color: var(--alert);
}

.materialInput > input:invalid:focus,
.materialInput > textarea:invalid:focus {
  border-color: var(--alert);
  border-top-color: transparent;
  outline: none;
}

.materialInput > input:invalid:focus + span,
.materialInput > textarea:invalid:focus + span {
  color: var(--placeholder);
}

.materialInput > input:invalid:focus + span::before,
.materialInput > input:invalid:focus + span::after,
.materialInput > textarea:invalid:focus + span::before,
.materialInput > textarea:invalid:focus + span::after {
  border-top-color: var(--alert) !important;
}

/* Disabled */
.materialInput > input:disabled,
.materialInput > input:disabled + span,
.materialInput > textarea:disabled,
.materialInput > textarea:disabled + span {
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
  border-top-color: transparent !important;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  pointer-events: none;
}

.materialInput > input:disabled + span::before,
.materialInput > input:disabled + span::after,
.materialInput > textarea:disabled + span::before,
.materialInput > textarea:disabled + span::after {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
}

.materialInput > input:disabled:placeholder-shown,
.materialInput > input:disabled:placeholder-shown + span,
.materialInput > textarea:disabled:placeholder-shown,
.materialInput > textarea:disabled:placeholder-shown + span {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38) !important;
}

.materialInput > input:disabled:placeholder-shown + span::before,
.materialInput > input:disabled:placeholder-shown + span::after,
.materialInput > textarea:disabled:placeholder-shown + span::before,
.materialInput > textarea:disabled:placeholder-shown + span::after {
  border-top-color: transparent !important;
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .materialInput > input,
    .materialInput > input + span,
    .materialInput > textarea,
    .materialInput > textarea + span,
    .materialInput > input + span::before,
    .materialInput > input + span::after,
    .materialInput > textarea + span::before,
    .materialInput > textarea + span::after {
      transition-duration: 0.1s;
    }
  }
}
