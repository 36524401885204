.icon {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  cursor: pointer;
  color: var(--text-secondary);
}

.icon:hover {
  color: var(--text-primary);
}

.icon:hover:active {
  color: var(--primary);
}
