.arrow {
  position: relative;
  transition: all ease 0.3s;
  cursor: pointer;
  margin-left: auto;
}

.arrow[data-open='true'] {
  transform: rotate(180deg);
}

.arrow[data-selected='false'] {
  fill: var(--placeholder);
}

.wrapper {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  transition: background-color ease 0.3s;
  border-radius: var(--input-border-radius);
  padding: 0 9px;
  border: 1px solid var(--border-color);
  width: 100%;
  height: var(--input-height);
  position: relative;
  background: #fff;
}

.wrapper[data-loading='true'] {
  cursor: wait;
}

.wrapper[data-open='true'] {
  border: 1px solid var(--primary);
}

.wrapper[data-open='false'] .options {
  box-shadow: none;
}

.options {
  position: absolute;
  top: calc(var(--input-height) + 4px);
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.25);
  background: #fff;
}

.option {
  display: block;
  padding: 8px 12px;
  cursor: pointer;
}

.option:hover {
  background: var(--bg-hover);
}

.option[data-active='true'],
.option:active {
  background: var(--primary);
  color: #fff;
}
