.leftCol {
    width: 260px;
    border-right: 1px solid var(--gray);
    padding-top: 24px;
    flex-shrink: 0;
    height: 100%;
    background: #fff;
}

.content {
    width: 100%;
    height: calc(100% - 74px);
    display: inline-flex;
}

.navLink {
    padding: 8px 20px;
    color: var(--text-secondary);
    text-decoration: none;
    width: 100%;
    display: inline-flex;
    gap: 1em;
    align-items: center;
    border-left: 2px solid transparent;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
  }
  
  .navLink:hover {
    color: var(--primary);
    background: var(--bg-hover);
  }
  
  .navLink[data-active="true"] {
    border-left: 2px solid var(--primary);
    color: var(--primary);
    background: var(--bg-hover);
  }
  
  .groupActive {
    background: var(--bg-hover);
  }
  
  .navLink svg {
    flex-shrink: 0;
  }
  
  .activeGroupRoot svg {
    color: var(--primary);
  }
  
  .navLink[data-nested='true'] {
    padding-left: 70px;
    font-weight: 400;
  }
  
  .navLink[data-nested='false'] {
    text-transform: uppercase;
  }
  
  .navLink[data-lastchild='true'] {
    padding-bottom: 16px !important;
    position: relative;
  }
  
  .navLink[data-lastchild='true']::before {
    content: '';
    position: absolute;
    background: #f5f7ff;
    left: -2px;
    bottom: 0;
    width: 2px;
    height: 8px;
    z-index: 500;
  }
  