.wrapper {
  position: relative;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  z-index: 2;
}
