.wrapper {
  display: inline-flex;
  gap: 36px;
}

.col {
  min-width: 285px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 320px;
}

.colWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.transferCol {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.row {
  display: inline-flex;
  gap: 1em;
  width: 100%;
  align-items: center;
  border-top: 1px solid var(--table-border-color);
  cursor: pointer;
}

.row:first-child {
  border-top: none;
}

.checkbox {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rowContent {
  padding: 12px;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.row:hover {
  background: var(--bg-hover);
  color: var(--primary);
}

.row:active {
  background: var(--accent);
}

.arrowRight {
  color: transparent;
  margin-left: auto;
  flex-shrink: 0;
}

.row:hover .arrowRight {
  color: var(--primary);
}

.arrowRight[data-column='not-selected'] {
  transform: rotate(-180deg);
}
