.card {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  transition: all ease 0.3s;
  height: fit-content;
}

.card[data-hover="true"] {
  cursor: pointer;
}

.card[data-cardtype='normal'] {
  padding: 32px;
}

.card[data-cardtype='small'] {
  padding: 24px;
}

.card[data-skeleton='true'] {
  overflow: hidden;
  background-color: var(--light-gray);
}

.card[data-hover='true']:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.card[data-skeleton='true']::after {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
