.icon {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: all ease 0.3s;
  color: rgba(92, 92, 119, 0.52);
}

.input {
  padding-left: 42px;
  padding-right: 24px;
  z-index: 2;
}

.input::placeholder {
  font-size: 16px;
  line-height: 19px;
}

.closeIcon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  z-index: 2;
  transition: all ease 0.3s;
  cursor: pointer;
}

.closeIcon:hover {
  filter: brightness(120%);
}

.loader {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  height: 20px;
  width: 20px;
  transition: all ease 0.3s;
}