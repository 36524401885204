.wrapper {
  position: fixed;
  z-index: 999;
  bottom: 40px;
  right: 2em;
  transform: translateX(calc(100% + 3em));
  transition: transform ease 0.3s;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-radius: var(--input-border-radius);
}

.wrapper[data-msgtype='error'] {
  background: var(--alert);
  color: #fff;
}

.wrapper[data-msgtype='info'] {
  background: var(--primary);
  color: #fff;
}

.wrapper[data-active='true'] {
  transform: translateX(0);
}
