.wrapper {
  border: 1px solid var(--gray);
  border-radius: calc(var(--input-border-radius) * 2);
  padding: 8px;
  width: 45px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.wrapper:hover {
  background: var(--bg-hover);
}

.wrapper:active,
.wrapper[data-active='true'] {
  background: var(--accent);
}
