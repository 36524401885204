.app-modal__shadow {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  overflow: auto;
  min-height: 100vh;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.app-modal {
  min-width: 800px;
  max-width: 1000px;
  margin: 10vh auto;
  background: #fff;
  border-radius: 1em;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.app-modal-animation.app-modal__shadow {
  animation-name: modalShadowAppear;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}

.app-modal-animation > .app-modal {
  transform-origin: center;
  animation-name: appModalAppear;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}

.app-modal.is-sm {
  min-width: 400px;
  max-width: 450px;
}

.app-modal.is-lg {
  width: 860px;
  min-width: 600px;
  max-width: 90vw;
}

.app-modal.is-hg {
  width: 90vw;
  min-width: 600px;
  max-width: 1600px;
}

.app-modal.is-tall {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-header {
  padding: 32px 24px;
  display: inline-flex;
  align-items: center;
  background: #f8f8f8;
  width: 100%;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  justify-content: space-between;
}

.modal-content {
  padding: 32px;
  gap: 16px;
  overflow: auto;
}

.modal-footer {
  display: inline-flex;
  width: 100%;
  padding: 24px 32px;
  justify-content: flex-end;
  gap: 1em;
  border-top: 1px solid rgba(92, 92, 119, 0.28);
}

.modal-footer__error {
  color: var(--alert);
  display: flex;
  margin-right: auto;
}

@media (max-width: 760px) {
  .app-modal,
  .app-modal.is-lg {
    min-width: 90vw;
    max-width: 100vw;
  }
}

@keyframes appModalAppear {
  from {
    opacity: 0.1;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalShadowAppear {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
