.ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.spinner {
  color: var(--primary);
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}

.ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}