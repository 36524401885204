.pageWithBackButton {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  gap: 48px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 44px;
  padding-top: 44px;
}

.pageWithBackButtonContentWrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 24px;
  height: fit-content;

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

.pageWithBackButtonContentWrapperButton {
  position: absolute;
  left: -80px;
}

.defaultPageWrapper {
  height: 100%;
  width: 100%;
  padding: 32px 44px;
  padding-top: 44px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.defaultPageWithBackButtonContent {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.defaultPage {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 32px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.defaultPageWithBackButton {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.defaultPageWithBackButtonButton {
  position: absolute;
  left: -40px;
}

.largePaddingPage {
  padding: 60px 244px;
  width: 100%;
  background: rgba(39, 39, 66, 0.02);
}
