/** Default input styles */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.etba-input {
  padding: 10px 15px;
  height: var(--input-height);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  outline: none;
  width: 100%;
  font-size: 14px;
}

.etba-input.is-select {
  padding-top: 0;
  padding-bottom: 0;
}

.etba-input::placeholder {
  color: var(--placeholder);
}

.etba-input:focus {
  border: 1px solid var(--primary);
}

.etba-input:invalid,
.etba-input[data-invalid='true'] {
  border: 2px solid var(--alert);
}

.etba-input_error {
  margin-left: 15px;
  margin-top: 5px;
}

.etba-input_error {
  margin-left: 15px;
  margin-top: 5px;
}

.etba-select {
  position: relative;
  height: var(--input-height);
}

.etba-select-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;
}

.etba-select-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  width: 8px;
  height: 10px;

  transition: all ease 0.3s;
  fill: var(--placeholder);
}

.etba-select-arrow.is-open {
  transform: translateY(-50%) rotate(180deg);
}

.etba-select-menu {
  position: absolute;
  top: var(--input-height);
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.4);
  border: none;
  z-index: 10;
}

.etba-select-item {
  cursor: pointer;
  padding: 6px 20px;
}

.etba-select-item.is-highlighted,
.etba-select-item.is-selected {
  background: var(--primary);
  color: #fff;
}

.etba-select-item:not(.is-selected):hover {
  background: var(--bg-hover);
}

.search-result.is-match {
  color: var(--primary);
}

/** Default button styles */
.etba-button {
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
}

.etba-button[data-theme='danger'] {
  border: 1px solid var(--alert);
  background: #fff;
}

.etba-button[data-theme='danger'] span {
  color: var(--alert);
  font-weight: 400;
}

.etba-button[data-theme='grey-outline'] {
  border: 1px solid var(--gray);
  color: var(--text-secondary);
  background: #fff;
}

.etba-button[data-theme='grey-outline']:hover {
  background: var(--bg-hover);
}

.etba-button[data-theme='grey-outline']:active {
  background: var(--accent);
}

.etba-button[data-theme='primary'] {
  background: var(--primary);
}

.etba-button[data-theme='primary']:hover {
  background: var(--primary-hover);
}

.etba-button[data-theme='primary'] span {
  color: #fff;
  font-weight: 400;
}

.etba-button[data-theme='grey'] {
  background: var(--gray);
  font-weight: 400;
}

.etba-button[data-theme='grey'] span {
  color: var(--dark);
}

.etba-button[data-theme='grey']:hover {
  filter: contrast(105%);
}

.etba-button[data-theme='grey']:active {
  filter: contrast(110%);
}

.etba-button[data-theme='lightgreen'] {
  background: var(--bg-success);
}

.etba-button[data-theme='lightgreen'] span {
  color: var(--text-primary);
}

.etba-button[data-theme='lightgreen']:hover {
  filter: contrast(105%);
}

.etba-button[data-theme='lightgreen']:active {
  filter: contrast(110%);
}

.etba-button[data-theme='primary']:hover,
.etba-button[data-theme='danger']:hover {
  filter: brightness(110%);
}

.etba-button[data-theme='primary']:active,
.etba-button[data-theme='danger']:active {
  filter: brightness(120%);
}

.etba-button[data-loading='true'] {
  position: relative;
}

.etba-button[data-loading='true'] span {
  visibility: hidden;
  opacity: 0;
}

.etba-button[data-loading='true']::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.etba-button[data-theme='danger'][data-loading='true']::after {
  border-top-color: var(--primary);
}

.etba-button:disabled {
  background: var(--gray) !important;
  cursor: not-allowed;
  filter: none !important;
}

.etba-button:disabled span {
  color: var(--gray);
}

.etba-button span {
  font-size: 16px;
  white-space: nowrap;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.tableContainer {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 46px;
}

.tableWrapper {
  flex: 1;
  position: relative;
  overflow-x: auto;
  background: #fff;
}

.tableLoaderContainer {
  cursor: wait;
  top: 0;
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background: #00000010;
}

.tableWrapper[data-loading='true'] table {
  pointer-events: none;
  cursor: not-allowed;
}

.table {
  border: 1px solid var(--table-border-color);
  height: 100%;
  width: 100%;
}

.table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table thead tr {
  border-bottom: none;
}

.table tr {
  border-bottom: 1px solid var(--table-border-color);
}

.table th {
  font-weight: bold;
  text-align: left;
  font-size: 12px;
  text-transform: uppercase;
  background: #fff;
  color: var(--text-primary);
  box-shadow: inset 0px -1px 0px var(--table-border-color);
  height: 40px;
}

.table th:hover {
  background: var(--bg-hover);
}

.table th:active {
  background: var(--accent);
}

.table th:nth-child(2) {
  color: var(--primary);
}

.table th > div {
  position: relative;
  padding: 12px 10px;
  height: 40px;
}

.table th > div:after {
  display: block;
  content: '';
  position: absolute;
  width: 1px;
  height: 23px;
  background: var(--table-border-color);
  right: 0;
  top: 8px;
}

.tableThResize {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 10px;
  cursor: col-resize;
  background: transparent;
  z-index: 10;
}

.tableThArrow {
  transition: all ease 0.3s;
  transform-origin: center center;
  opacity: 1;
}

.tableThArrow[data-sort='1'] {
  transform: rotate(-180deg);
}

.tableThArrow[data-sort='0'] {
  opacity: 0;
  transform: rotate(-180deg);
}

.table--checkbox-col {
  width: 40px;
  vertical-align: middle;
  text-align: center;
}

.table--checkbox-col div:after {
  width: 0px !important;
}

.table--checkbox-col input {
  width: 20px;
}

.table td {
  padding: 12px 10px;
  font-size: 14px;
  color: var(--text-primary);
}

.table tr:hover {
  background: var(--bg-hover);
  cursor: pointer;
}

.table tr:active {
  background: var(--accent);
  cursor: pointer;
}

.table tr:has(input[type='checkbox']:checked) {
  background: var(--bg-hover) !important;
}

.table tr:has(.tableOptionsRow:hover) {
  background: #fff !important;
}

.table td:nth-child(2) {
  color: var(--primary);
  font-weight: 700;
}

.tableOptionsRow {
  background: #fff !important;
  padding: 0 !important;
  position: relative;
  height: 100%;
}

.tableOptionsRow:hover {
  background: var(--bg-hover) !important;
}

.tableOptionsRow:active {
  background: var(--accent) !important;
}

.tableOptions {
  transform: rotate(90deg);
  width: 15px;
  height: 15px;
}

.tableFooter {
  flex-shrink: 0;
  width: 100%;
  justify-content: flex-end;
}

.tableArrow {
  border-radius: 4px;
  cursor: pointer;
  padding: 6px;
  width: 24px;
  height: 32px;
}

.tableArrowLeft {
  transform: rotate(180deg);
}

.tableArrow:hover {
  background: var(--bg-hover);
}

.tableArrow:active {
  background: var(--accent);
}

.tableArrow[data-disabled='true'] {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.table-contextmenu {
  position: absolute;
  right: 25px;
  bottom: 50%;
  transform: translateY(50%);
  white-space: nowrap;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  background-color: #fff;
  z-index: 10;
  overflow: hidden;
}

.table-contextmenu-option {
  display: block;
  padding: 8px 12px;
  white-space: nowrap;
}

.table-contextmenu-option:hover {
  background: var(--primary);
  color: #fff;
}

.table-contextmenu-option[data-danger='true']:hover {
  background: var(--alert);
  color: #fff;
}

.optionsIconWrapper {
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.optionsIcon {
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
}

.optionsIconWrapper:hover {
  background: var(--bg-hover);
}

.options-contextmenu {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + 12px));
  white-space: nowrap;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: calc(var(--input-border-radius) * 2);

  background-color: #fff;
  z-index: 10;
  overflow: hidden;
  padding: 28px 0;
}

.options-contextmenu-option {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  padding: 8px 20px;
  white-space: nowrap;
  cursor: pointer;
  border-left: 2px solid transparent;
}

.options-contextmenu-option:hover,
.options-contextmenu-option[data-active='true'] {
  border-left: 2px solid var(--primary);
  background: var(--bg-hover);
}

.options-contextmenu-option[data-danger='true']:hover {
  border-left: 2px solid var(--alert);
  background: var(--bg-hover);
}

.panel-content {
  min-width: 200px;
  position: absolute;
  white-space: nowrap;
  z-index: 200;
  background-color: #fff !important;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px 20px 36px;

  display: flex;
  flex-direction: column;
}

.panel-content.options-content {
  padding: 8px 0 16px;
}

.panel-content-options-separator {
  height: 1px;
  background: rgba(92, 92, 119, 0.28);
  margin: 16px 0;
}

.panel-content.bottom-right {
  right: 0;
  transform: translateY(0.5em);
}

.panel-content.top-center {
  right: 0;
  transform-origin: top center;
}

.panel-content.content-context-menu {
  width: fit-content;
}
