.select {
  cursor: pointer;
}

.select:hover {
  background: var(--bg-hover);
}

.select:active {
  background: var(--accent);
}
