.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 3.2em;
  height: 1.6em;
  margin: 0;
}

.toggleSwitch > input {
  display: none;
}

.toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-gray);
  transition: 0.4s;
  border-radius: 1.6em;
  box-shadow: 1px 1px 3px 0 #00000050 inset;
}

.toggleSlider:before {
  position: absolute;
  content: '';
  height: 1.2em;
  width: 1.2em;
  top: 0.2em;
  left: 0.2em;
  background-color: white;
  transition: 0.4s;
  border-radius: 0.6em;
}

input:checked + .toggleSlider {
  background-color: var(--primary) !important;
}

input:checked + .toggleSlider:before {
  transform: translateX(1.6em);
}

.toggle-row {
  display: flex;
  align-items: center;
  margin-left: -16px;
}
