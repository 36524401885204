.user {
    display: inline-flex;
    gap: 0.75em;
    align-items: center;
    padding: 5px;
    border-radius: 8px;
}

.separator {
    border-left: 1px solid var(--primary);
    height: 20px;
}

.exit:hover {
    color: var(--alert);
}