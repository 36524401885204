.userIcon {
    border-radius: 50%;
    padding: 8px;
    border: 1px solid var(--primary);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userIconSvg {
    width: 16px;
    height: 16px;
    color: var(--tertiary);
}