.wrapper {
  display: inline-flex;
  width: 100%;
}

.option {
  padding: 10px 16px;

  cursor: pointer;
  transition: all ease 0.3s;
  font-size: 12px;
  text-transform: uppercase;
}

.option[data-selected='true'] {
  box-shadow: inset 0px -2px 0px var(--primary);
  color: #4a4a68;
}

.option:hover {
  color: #4a4a68 !important;
}

.option[data-selected='false'] {
  box-shadow: inset 0px -1px 0px #8c8ca2;
  color: #8c8ca2;
}
