.flex-space-between {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.flex-form {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
}

.flex-grow-1 {
  flex-grow: 1;
}

.inline-flex {
  display: inline-flex;
  width: 100%;
  gap: 12px;
}

.form-footer {
  padding-top: 45px;
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.grid-form-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.recipient__edit-form-grid {
  display: grid;
  gap: 1em;
}

.auto-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
}
