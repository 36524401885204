.wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.header {
    height: 74px;
    box-shadow: inset 0 -1px 0 rgba(140,140,161,.3);
    display: flex;
    padding: 0 72px;
    position: relative;
    align-items: center;
}

.logo {
    width: 194px;
    cursor: pointer;
    position: absolute;
    left: 124px;
    top: 50%;
    transform: translateY(-50%);
}

.profile {
    position: absolute;
    right: 124px;
    top: 50%;
    transform: translateY(-50%);
}

.content {
    flex: 1;
    overflow: auto;
    flex-direction: column;
    background: rgba(39, 39, 66, 0.02);
}

.contentWithMenuWrapper {
    width: 100%;
    height: calc(100% - 64px);
    display: inline-flex;
    padding-left: 259px;
}

.menuWrapper {
    width: 350px;
    background: #fff;
    margin-top: 17px;
    flex-shrink: 0;
    height: calc(100% - 32px);
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
