:root {
  --primary: #2c5cfa;
  --primary-hover: #063ef5;
  --secondary: #efabe1;
  --tertiary: #a69af7;
  --dark: #0e0e2c;
  --success: #31d0aa;
  --alert: #ef5da8;
  --text-secondary: rgba(24, 42, 82, 0.6);
  --text-primary: #182a52;
  --text-subtitle: #8c8ca1;
  --accent: #ecf1f4;
  --light: #fafafa;
  --white: #ffffff;
  --white50: #ffffff50;
  --gray: #5c5c7747;
  --light-gray: rgba(171, 171, 190, 0.28);
  --border-color: rgba(92, 92, 119, 0.28);
  --placeholder: rgba(92, 92, 119, 0.52);
  --bg-hover: #f5f7ff;
  --bg-danger: #ffe3f2;
  --bg-success: #ddf7f0;
  --bg-warning: #fff2c5;
  --bg-stripe: rgba(0, 32, 51, 0.05);
  --table-border-color: rgba(140, 140, 161, 0.3);
  --separator: #c6c6c8;

  --input-border-radius: 4px;
  --input-height: 40px;
}
