.value {
  padding: 5px;
}

.value:hover,
.value[data-open='true'] {
  background: var(--bg-hover);
  border-radius: 2px;
}

.value:active {
  background: var(--accent);
}

.arrow {
  transform: rotate(90deg);
  cursor: pointer;

  transition: all ease 0.3s;
  width: 12px;
  height: 12px;
}

.arrow[data-open='true'] {
  transform: rotate(-90deg);
}

.arrow:hover {
  filter: brightness(120%);
}

.panelContent {
  transition: all ease 0.3s;
  position: absolute;
  transform: translateY(calc(-100% - 35px));
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  width: 150%;
  border-radius: 5px 5px 5px 0;
}

.option {
  padding: 5px 10px;
  cursor: pointer;
}

.option:hover {
  background: var(--bg-hover);
}
