.page-content {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.inline-flex-gap {
  display: inline-flex;
  align-items: center;
  gap: 1em;
}

.flex-lines {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

.flex-lines.gap24 {
  gap: 24px;
}

::-webkit-scrollbar {
  height: 16px;
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #fbfbfb;
  border: 1px solid #dcdce2;

  height: 16px;
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: content-box;
}

.svg-clickable {
  cursor: pointer;
}

.svg-clickable:hover {
  filter: brightness(120%);
}

.svg-clickable.hover-bg {
  padding: 5px;
  border-radius: 4px;
}

.svg-clickable.hover-bg:hover {
  background: var(--accent);
  filter: none;
}

.line_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-page-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 1em;
  padding-top: 10%;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
}

.sides-padding {
  padding: 0 259px;
}
