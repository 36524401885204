.content {
    overflow: hidden;
}

.visible {
    overflow: visible;
}

.hidden {
    overflow: hidden !important;
}

.auto {
    overflow: auto !important;
}

.resolved {
    max-height: 10000px !important;
    opacity: 1 !important;
}