.container {
  border: 1px solid rgba(92, 92, 119, 0.28);
  border-radius: 8px;
  height: 40px;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container:hover {
  background: var(--bg-hover);
}

.container:active {
  background: var(--accent);
}

.container svg {
  transition: all ease 0.3s;
}

.container:active svg {
  transform: translateX(-10%);
}
