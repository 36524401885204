@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: var(--text-primary);
}

* {
  font-size: inherit;
}

span,
p,
h2,
h3 {
  white-space: pre-wrap;
}
/*
.text-20 {
  font-size: 20px;
}

.text-24 {
  font-size: 24px;
}*/

.text-400 {
  font-weight: 400;
}

.text-200 {
  font-weight: 400;
}

.text-700 {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.page--header {
  font-weight: 700;
  font-size: 24px;
  flex-shrink: 0;
}

.text--link {
  color: var(--primary);
  cursor: pointer;
}

.text--link-underline {
  border-bottom: 1px dashed var(--primary);
}

.text--link:hover {
  filter: brightness(140%);
}

.text--link:active {
  filter: brightness(160%);
}

.text--body-l {
  font-size: 18px;
  line-height: 22px;
}

.text--body-m {
  font-size: 16px;
  line-height: 19px;
}

.text--body-s {
  font-size: 14px;
  line-height: 17px;
}

.text--body-xs {
  font-size: 12px;
  line-height: 15px;
}

.text--body-xxs {
  font-size: 11px;
  line-height: 13px;
}

.text--caption-l {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.text--caption-m {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.text--caption-s {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.text--caption-xs {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.text--heading-h1 {
  font-size: 48px;
  line-height: 58px;
}

.text--heading-h2 {
  font-size: 40px;
  line-height: 48px;
}

.text--heading-h3 {
  font-size: 32px;
  line-height: 39px;
}

.text--heading-h4 {
  font-size: 24px;
  line-height: 29px;
}

.text--heading-h5 {
  font-size: 20px;
  line-height: 24px;
}

.text--blue {
  color: var(--primary);
}

.text--primary {
  color: var(--text-primary);
}

.text--success {
  color: var(--success);
}

.text--alert {
  color: var(--alert) !important;
}

.text--warn {
  color: var(--bg-warning) !important;
}

.text--secondary {
  color: var(--text-secondary);
}

.text--white50 {
  color: var(--white50);
}

.text--white {
  color: #fff;
}

.text--grey {
  color: var(--gray);
}

.text--placeholder {
  color: var(--placeholder);
}

.text--bold {
  font-weight: bold;
}
